@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

$primary: map-get($foundation-palette, primary);
$secondary: map-get($foundation-palette, secondary);
$emperor: #777;
$tundora: #555;

body {background: url('../img/polaroid.png') repeat;}

p, li {color: $emperor;}

.pad {padding: 25px 0;}

.top-bar, .title-bar {
    padding: 15px;
    background-color: $primary;
    ul {background: transparent;}
    li a, li a:hover {padding: 11.2px 30; color: $white;}
    .menu-icon::after {
        background: #fff;
        box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
    }  
}

.orbit-container {border: 5px solid $white; box-shadow: 0 0 5px #CCC;}

#content {min-height: 100vh;}

#sponsors {
    background: $white;
    border-top: 2px solid $primary;
}

.reveal {
    h5 {color: $primary; font-weight: 500;}
    img {float: left; margin-right: 10px; }
}
.reveal-overlay {background-color: rgba(10, 10, 10, 0.95);}


footer
{
  background-color: $tundora;
  color: white;
  padding: 15px 0;
  border-top: solid 2px $primary;
  p{color: $white; margin-bottom: 0; font-size: 13.5px;}
}